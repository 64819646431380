import React from "react"
import { Link } from "gatsby"
import HeroIcon from "./hero-icon"

const FeedItem = ({ post }) => {
  return (
  <li className="feed-item">
    <article
      className="post-list-item"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <span className="avatar"><HeroIcon hero={post.frontmatter.author} /></span>
        <Link to={post.fields.slug} itemProp="url">
          <small>{post.frontmatter.date}</small>
        </Link>                  
      </header>
      <section>
        <div
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
          itemProp="description"
        />
      </section>      
    </article>
  </li>
  )
}

export default FeedItem