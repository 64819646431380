import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bulletin = () => {
  const data = useStaticQuery(graphql`
  query {
    banner: file(absolutePath: {regex: "/animajor-banner.jpg/"}) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  const banner = data?.banner?.childImageSharp?.fluid
  return (
    <section className="bulletin">
      <div className="banner">
        <Image className="bulletin-img" fluid={banner} alt="bulletin_banner" />
        {/* <video autoplay="true" loop="true" preload="auto"><source type="video/mp4" src="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/homepage/dota_montage_02.mp4" /></video> */}
      </div>
    </section>
  )
}

export default Bulletin