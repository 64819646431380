import React from "react"
import FeedItem from "../components/feed-item"

const NewsFeed = ({ posts }) => {
  return (
  <ol className="news-feed">
    {posts.map(post => {
      return (
        <FeedItem key={post.fields.slug} post={post} />
      )
    })}
  </ol>
  )
}

export default NewsFeed