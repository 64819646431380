import React from "react"
import { Link, graphql } from "gatsby"
import Bulletin from "../components/bulletin"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsFeed from "../components/newsfeed"

const BlogFeed = (props) => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : '/'+(currentPage - 1).toString()
  const nextPage = '/'+(currentPage + 1).toString()

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Home" />
        <Bulletin />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <Bulletin />
      <NewsFeed posts={posts} />
      {/* Pagination */}
      <ul className="feed-pagination">
        {!isFirst && (
          <Link to={prevPage} rel="prev">← Newer</Link>
        )}
        {Array.from({ length: numPages }, (_, i) => {
          if (currentPage===i+1)
            return (
              <li key={`pagination-number${i + 1}`}>
                <span>{i + 1}</span>
              </li>
            )
          return (
            <li key={`pagination-number${i + 1}`}>
              <Link to={`/${i === 0 ? "" : i + 1}`}>
                {i + 1}
              </Link>
            </li>
          )
        }
        )}
        {!isLast && (
          <Link to={nextPage} rel="next">Older →</Link>
        )}
      </ul>
    </Layout>
  )
}

export default BlogFeed

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blog/"  }}, 
      sort: { fields: [frontmatter___date], order: DESC },
      limit: $limit,
      skip: $skip)
    {
      nodes {
        excerpt(format: HTML, pruneLength: 480)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          author
        }
      }
    }
  }
`
