import React from "react"
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const HeroIcon = ({ hero }) => {
  const data = useStaticQuery(graphql`
  query {
    allImageSharp(filter: {fixed: {originalName: {regex: "/npc_dota_hero/"}}}) {
      edges {
        node {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  }
  `)
  const defaultImg = data.allImageSharp.edges
  .filter(edge => edge.node.fixed.originalName === "npc_dota_hero_dota2_png.png")
  .map(myHero => <Image key={myHero} fixed={myHero.node.fixed} alt={hero} />);
  const heroImg = data.allImageSharp.edges
  .filter(edge => edge.node.fixed.originalName === "npc_dota_hero_"+hero+"_png.png")
  .map(myHero => <Image key={myHero} fixed={myHero.node.fixed} alt={hero} />);
  return (
    <>{ heroImg && heroImg.length ? heroImg : defaultImg }</>
  )
}

HeroIcon.propTypes = {
  hero: PropTypes.string,
};

HeroIcon.defaultProps = {
  hero: 'dota2',
};


export default HeroIcon